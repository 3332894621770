import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";

import { SHIFT_DETAILS_TIMEKEEPING_FULL_PATH, SHIFT_DETAILS_TIMEKEEPING_PATH } from "../path";
import { ShiftDetailsError } from "./ShiftDetailsError";
import { ShiftDetailsLoadingSkeleton } from "./ShiftDetailsLoadingSkeleton";
import { ShiftDetailsPage } from "./ShiftDetailsPage";
import { ShiftTimekeepingPage } from "./ShiftTimekeepingPage";

export function ShiftDetailsRoute() {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const { shiftId } = useParams<{ shiftId: string }>();
  const { data: shiftResponse, isLoading, isError } = useGetShift(shiftId);

  if (isLoading) {
    return <ShiftDetailsLoadingSkeleton />;
  }

  if (isError) {
    return <ShiftDetailsError />;
  }

  const isClockedIn = Boolean(shiftResponse.response.clockInOut?.start);
  const isOnTimekeepingPage = location.pathname.endsWith(SHIFT_DETAILS_TIMEKEEPING_PATH);

  if (isClockedIn && !isOnTimekeepingPage) {
    return <Redirect to={SHIFT_DETAILS_TIMEKEEPING_FULL_PATH} />;
  }

  if (!isClockedIn && isOnTimekeepingPage) {
    return <Redirect to={url} />;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <ShiftDetailsPage />
      </Route>
      <Route path={`${path}${SHIFT_DETAILS_TIMEKEEPING_PATH}`}>
        <ShiftTimekeepingPage />
      </Route>
    </Switch>
  );
}
