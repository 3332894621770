/* eslint-disable import/max-dependencies */
import { List } from "@clipboard-health/ui-components";
import { useModalState } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
import { Alert, ThemeProvider } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useCurrentDate } from "@src/appV2/lib/utils/useCurrentDate";
import { convertToGeoLocation } from "@src/appV2/Location";
import { type ShiftPeriod, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { isAfter, parseISO } from "date-fns";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { PageWrapper } from "../../components/PageWrapper";
import { ShiftDurationListItem } from "../../Shift/DurationListItem";
import { ShifttHighlightsAndMapListItem } from "../../Shift/HighlightsAndMapListItem";
import { ShiftWorkplaceListItem } from "../../Shift/WorkplaceListItem";
import { formatTime } from "../../utils/formatTime";
import { getShiftWorkDurationInHours } from "../../utils/getShiftWorkDuration";
import { PageContent } from "../components/PageContent";
import { useGetShiftDetailsData } from "./api/useGetShiftDetailsData";
import { CancelShiftBottomSheet } from "./components/CancelShiftBottomSheet";
import { ShiftDetailsFooter } from "./components/Footer";
import { ShiftDetailsHeaderWrapper } from "./components/HeaderWrapper";
import { HorizontalCardWrapperListItem } from "./components/HorizontalCardWrapperListItem";
import { ShiftDetailsPageSkeleton } from "./components/Skeleton";

export function ShiftDetailsPage() {
  const currentDate = useCurrentDate(1000);
  const history = useHistory();

  const cancelShiftModalState = useModalState();
  const { shiftId } = useParams<{ shiftId: string }>();

  const {
    shift,
    shiftState,
    shiftError,
    breakPolicyNoteId,
    requiresBreakPolicyAcknowledgement,
    isLoading: isPageDataLoading,
    refetchShift,
  } = useGetShiftDetailsData({
    shiftId,
  });

  const isPageDataSuccess = !isPageDataLoading && !shiftError;
  const isPageDataError = !isPageDataLoading && shiftError;

  const shiftStartDate = isDefined(shift) ? parseISO(shift.start) : new Date();
  const formattedStartTime = isPageDataSuccess
    ? formatTime(shiftStartDate, shift?.facility.tmz)
    : "";

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper variant="tertiary">
        <ShiftDetailsHeaderWrapper
          shiftName={shift?.name}
          title={
            isPageDataSuccess
              ? `Prepare for your ${shift?.agentReq} shift at ${formattedStartTime}`
              : ""
          }
        />

        <PageContent>
          <PullToRefresh
            onRefresh={async () => {
              await refetchShift();
            }}
          />

          {isPageDataLoading ? <ShiftDetailsPageSkeleton /> : null}

          {isPageDataError ? (
            <Alert sx={{ marginY: 4 }} severity="error">
              Something went wrong while loading this shift. Please try again.
            </Alert>
          ) : null}

          {!isPageDataLoading && isDefined(shift) ? (
            <List>
              <ShiftDurationListItem
                shiftTimeSlot={shiftPeriodToTimeSlot[shift.name as ShiftPeriod]}
                shiftStartIso={shift.start}
                shiftEndIso={parseISO(shift.end).toISOString()}
                shiftDurationInHours={getShiftWorkDurationInHours(
                  { start: shift.start, end: shift.end },
                  shift.facility.requiresLunchBreak ?? false
                )}
                workplaceTimeZone={shift.facility.tmz}
              />

              <HorizontalCardWrapperListItem
                shift={shift}
                onCancelShift={() => {
                  cancelShiftModalState.openModal();
                }}
              />

              <ShiftWorkplaceListItem
                workplaceId={shift.facilityId}
                workplaceName={shift.facility.name}
                workplaceType={shift.facility.type ?? ""}
                workplaceLocation={convertToGeoLocation(shift.facility.geoLocation.coordinates)}
                onClick={() => {
                  history.push(
                    generatePath(DeprecatedGlobalAppV1Paths.FACILITY_DETAIL, {
                      facilityId: shift.facilityId,
                    })
                  );
                }}
              />

              <ShifttHighlightsAndMapListItem
                workplaceId={shift.facilityId}
                workplaceName={shift.facility.name}
                workplaceLocation={convertToGeoLocation(shift.facility.geoLocation.coordinates)}
                lateCancellationPeriod={shift.facility.lateCancellation?.period ?? 0}
                lateCancellationFeeHours={shift.facility.lateCancellation?.feeHours ?? 0}
                requiresLunchBreak={shift.facility.requiresLunchBreak}
                providesRestBreaks={shift.facility.providesRestBreaks}
              />
            </List>
          ) : null}
        </PageContent>

        {!isPageDataLoading && isDefined(shift) && isDefined(shiftState) && (
          <ShiftDetailsFooter
            shift={shift}
            shiftState={shiftState}
            breakPolicyNoteId={breakPolicyNoteId ?? ""}
            requiresBreakPolicyAcknowledgement={requiresBreakPolicyAcknowledgement}
            onCancelShift={() => {
              cancelShiftModalState.openModal();
            }}
          />
        )}
      </PageWrapper>

      {isDefined(shift) ? (
        <CancelShiftBottomSheet
          modalState={cancelShiftModalState}
          isNoCallNoShow={
            !isDefined(shift.clockInOut?.start) && isAfter(currentDate, shiftStartDate)
          }
          currentDate={currentDate}
          shiftStartDate={shiftStartDate}
          shiftId={shiftId}
        />
      ) : null}
    </ThemeProvider>
  );
}
/* eslint-enable import/max-dependencies */
