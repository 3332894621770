import { Text } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
import { Alert, ThemeProvider } from "@mui/material";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import { useParams } from "react-router-dom";

import { PageWrapper } from "../../components/PageWrapper";
import { PageContent } from "../components/PageContent";

export function ShiftTimekeepingPage() {
  const { shiftId } = useParams<{ shiftId: string }>();

  const {
    data: shiftData,
    isSuccess: isShiftSuccess,
    isLoading: isShiftLoading,
    isError: isShiftError,
    refetch: refetchShift,
  } = useGetShift(shiftId ?? "", {
    enabled: isDefined(shiftId) && shiftId.length > 0,
  });

  const shift = isShiftSuccess ? shiftData?.response : undefined;

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper variant="tertiary">
        <PageContent>
          <PullToRefresh
            onRefresh={async () => {
              await refetchShift();
            }}
          />

          {isShiftLoading ? <Text>Loading...</Text> : null}

          {isShiftError ? (
            <Alert sx={{ marginY: 4 }} severity="error">
              Something went wrong while loading this shift. Please try again.
            </Alert>
          ) : null}

          {isDefined(shift) ? <Text>{shift.name}</Text> : null}
        </PageContent>
      </PageWrapper>
    </ThemeProvider>
  );
}
