import { LoadingSpinner } from "@clipboard-health/ui-components";
import { getTheme } from "@clipboard-health/ui-theme";
import { Box, ThemeProvider } from "@mui/material";

import { PageWrapper } from "../../components/PageWrapper";
import { PageContent } from "../components/PageContent";

export function ShiftDetailsLoadingSkeleton() {
  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper variant="tertiary">
        <PageContent>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <LoadingSpinner size="large" />
          </Box>
        </PageContent>
      </PageWrapper>
    </ThemeProvider>
  );
}
