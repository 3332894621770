import { getTheme } from "@clipboard-health/ui-theme";
import { Alert, Box, Stack, ThemeProvider } from "@mui/material";
import { useHistory } from "react-router-dom";

import { IconButton } from "../../components/IconButton";
import { PageWrapper } from "../../components/PageWrapper";
import { PageContent } from "../components/PageContent";

export function ShiftDetailsError() {
  const history = useHistory();

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper variant="tertiary">
        <Stack sx={{ paddingTop: 5, paddingX: 6 }}>
          <IconButton
            iconType="arrow-left"
            variant="outlined"
            onClick={() => {
              history.goBack();
            }}
          />
        </Stack>
        <PageContent>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Alert severity="error">
              There was an error loading the shift details. Please try again.
            </Alert>
          </Box>
        </PageContent>
      </PageWrapper>
    </ThemeProvider>
  );
}
